import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <span>
        <Link className="t--epsilon" to="/privacy">
          Privacy
        </Link>{" "}
        <Link className="t--epsilon" to="/terms">
          Terms & Conditions
        </Link>
      </span>
      <span>
        <a className="t--epsilon" href="http://github.com/atelierdisko/sesame">
          GitHub
        </a>{" "}
        <a className="t--epsilon" href="http://atelierdisko.de/">
          Atelier Disko, 2020
        </a>
      </span>
    </footer>
  );
};

export default Footer;
