import React from "react";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";

const Privacy = () => {
  return (
    <div className="legal-container">
      <div className="legal">
        <div className="legal-header">
          <Link to="/" className="t--alpha">
            Sesame
          </Link>
        </div>

        <h2>Privacy Policy</h2>
        <p>
          Your privacy is important to us. It is Atelier Disko's policy to
          respect your privacy regarding any information we may collect from you
          across our website,{" "}
          <a href="http://sesame.atelierdisko.de">
            http://sesame.atelierdisko.de
          </a>
          , and other sites we own and operate.
        </p>

        <p>
          We currently do not collect any personal information or analyze it in
          any way. Secrets are encrypted client side with AES-256 using a
          passphrase you provided or generated. At no point the server stores
          unencrypted secrets.
        </p>

        <p>
          Our website may link to external sites that are not operated by us.
          Please be aware that we have no control over the content and practices
          of these sites, and cannot accept responsibility or liability for
          their respective privacy policies.
        </p>

        <p>
          You are free to refuse our request for your personal information, with
          the understanding that we may be unable to provide you with some of
          your desired services.
        </p>

        <p>
          Your continued use of our website will be regarded as acceptance of
          our practices around privacy and personal information. If you have any
          questions about how we handle user data and personal information, feel
          free to contact us.
        </p>

        <p>This policy is effective as of 24 July 2020.</p>
      </div>

      <Footer />
    </div>
  );
};

export default Privacy;
