import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./variables.css";
import "./typography.css";

import Index from "./pages/Index/Index";
import Reveal from "./pages/Reveal/Reveal";
import Terms from "./pages/Terms/Terms";
import Privacy from "./pages/Privacy/Privacy";

const App = () => {
  return (
    <div className="app">
      <Router>
        <Switch>
          <Route path="/" exact={true}>
            <div className="util-container">
              <Index />
            </div>
          </Route>

          <Route path="/reveal/:hash">
            <div className="util-container">
              <Reveal />
            </div>
          </Route>

          <Route path="/terms" exact={true}>
            <Terms />
          </Route>

          <Route path="/privacy" exact={true}>
            <Privacy />
          </Route>

          <Redirect from="*" to="/" />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
